<template>
  <nav class="mdc-drawer__content mdc-list">
    <router-link
      v-for="option in options"
      :key="option.route"
      :to="option.route"
      class="mdc-list-item"
    >
      <i
        class="material-icons mdc-list-item__graphic"
        aria-hidden="true"
        v-text="option.icon"
      />
      <span v-text="option.label" />
    </router-link>
  </nav>
</template>

<script>
export default {
  data: () => ({
    options: [
      {
        label: 'Subscription',
        route: '/account/subscription',
        icon: 'monetization_on',
      },
      { label: 'Email Accounts', route: '/account/email', icon: 'email' },
      // { label: 'Text Accounts', route: '/account/text', icon: 'text' },
      { label: 'Change Password', route: '/account/password', icon: 'vpn_key' },
    ],
  }),
};
</script>
